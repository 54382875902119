<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/services">Leistungen</router-link>
        <p>
          {{ service.name }}
        </p>
      </h1>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="content-wrap">
            <h3>Leistung</h3>

            <div class="form-wrap select-wrap">
              <label for="status">Status</label>
              <select v-model="service.status" class="form-input">
                <option key="hidden" value="hidden">Ausgeblendet</option>
                <option key="visible" value="visible">Sichtbar</option>
                <option key="deleted" value="deleted">Gelöscht</option>
              </select>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="name" class="form-label">Name</label>
                  <input v-model="service.name" class="form-input input-grey" type="text" name="name" id="name">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="price" class="form-label">Preis</label>
                  <currency-input v-model="service.price"
                    :options="{ currency: 'EUR', locale: 'de-DE', hideCurrencySymbolOnFocus: false }"
                    class="form-input" />
                </div>
              </div>
            </div>

            <div v-if="languages.length > 1" class="translation-wrap">
              <div v-for="lang in languages" v-bind:key="lang.id">
                <div v-if="lang.slug != 'de'" class="form-wrap">
                  <label :for="'name_' + lang.id" class="form-label">Name ({{ lang.name }})</label>
                  <input v-model="service['name_i18n_' + lang.slug]" class="form-input input-grey" type="text"
                    :name="'name_' + lang.id" :id="'name_' + lang.id">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="duration" class="form-label">Dauer (in min)</label>
                  <input v-model="service.duration" class="form-input input-grey" type="text" name="duration"
                    id="duration">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap select-wrap">
                  <label for="category_id">Kategorie</label>
                  <select v-model="service.category_id" class="form-input">
                    <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{
                      category.name }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="web_url" class="form-label">Link zur Webseite</label>
              <input v-model="service.web_url" class="form-input" type="text" name="web_url" id="web_url">
            </div>

            <div class="form-wrap">
              <label for="online_request_url" class="form-label">Link zum Online-Antrag</label>
              <input v-model="service.online_request_url" class="form-input" type="text" name="online_request_url"
                id="online_request_url">
            </div>

            <div class="form-wrap">
              <label for="online_request_text" class="form-label">Text für Online-Antrag</label>
              <input v-model="service.online_request_text" class="form-input" type="text" name="online_request_text"
                id="online_request_text">
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="cost_center" class="form-label">Kostenstelle</label>
                  <input v-model="service.cost_center" class="form-input input-grey" type="text" name="cost_center"
                    id="cost_center">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="gl_account" class="form-label">Sachkonto</label>
                  <input v-model="service.gl_account" class="form-input input-grey" type="text" name="gl_account"
                    id="gl_account">
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="slot_amount" class="form-label">Wie viele Termine sollen parallel hinterlegt werden
                können?<small style="display:block;opacity:0.5;margin-top:3px">(Wenn dieses Feld leer ist, greift die
                  Option beim Standort)</small></label>
              <input v-model="service.slot_amount" class="form-input" type="text" name="slot_amount" id="slot_amount">
            </div>

            <div class="form-wrap">
              <label for="description">Beschreibung</label>
              <textarea v-model="service.description" id="description" name="description" rows="2"
                class="form-input"></textarea>
            </div>

            <div v-if="languages.length > 1" class="translation-wrap">
              <div v-for="lang in languages" v-bind:key="lang.id">
                <div v-if="lang.slug != 'de'" class="form-wrap">
                  <label :for="'description_' + lang.id" class="form-label">Beschreibung ({{ lang.name }})</label>
                  <textarea v-model="service['description_i18n_' + lang.slug]" :name="'description_' + lang.id"
                    :id="'description_' + lang.id" rows="4" class="form-input"></textarea>
                </div>
              </div>
            </div>

            <div class="form-wrap" style="margin: 20px 0 10px 0">
              <input v-model="service.multi_select_possible" type="checkbox" id="multi_select_possible2"
                name="multi_select_possible2" style="display:inline-block;vertical-align:middle;margin-right:5px">
              <label for="multi_select_possible2" style="display:inline-block;vertical-align:middle;margin:0">Soll diese
                Leistung mehrfach ausgewählt werden dürfen?</label>
            </div>

            <div class="form-wrap select-wrap">
              <label for="category_id">Verkürzte Zeit bei 1 Person in Kombination mit anderer Leistung?</label>
              <select v-model="service.solo_time_reduced" class="form-input">
                <option v-bind:key="true" v-bind:value="true">Ja</option>
                <option v-bind:key="false" v-bind:value="false">Nein</option>
              </select>
            </div>

            <div class="form-wrap">
              <label for="reduced_duration" class="form-label">Verkürzte Dauer (in min)</label>
              <input v-model="service.reduced_duration" class="form-input input-grey" type="text" name="reduced_duration"
                id="reduced_duration">
            </div>

            <div class="form-wrap">
              <label for="service_type" class="form-label">Wo wird diese Leistung erbracht?</label>
              <select v-model="service.service_type" class="form-input">
                <option key="on_site" value="on_site">Vor Ort</option>
                <option key="ms_teams" value="ms_teams">MS Teams Meeting</option>
                <option key="phone" value="phone">Telefonisch</option>
              </select>
            </div>

            <p style="margin-bottom:0;text-align:right">
              <a @click="update_service" class="button button-red">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk"
                  class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512">
                  <path fill="currentColor"
                    d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z">
                  </path>
                </svg>
                <span>Daten speichern ›</span>
              </a>
            </p>

          </div>
        </div>
        <div class="col-12">
          <div class="content-wrap">
            <h3>Schalter</h3>
            <div v-if="service_counters && service_counters.length == 0" class="alert alert-info" style="margin-bottom:0">
              <p>Keine Schalter hinterlegt.</p>
            </div>
            <div v-else>
              <ul class="content-list">
                <li v-for="counter in service_counters" v-bind:key="counter.id">
                  {{ counter.counter.name }}
                  <span v-if="counter.match_type && counter.match_type == 'first_letter'">– Anfangsbuchstabe
                    ({{ counter.match_value }})</span>
                  <span v-if="counter.users && counter.users.length > 0"><small> – [{{ counter.users.join(", ")
                  }}]</small></span>

                  <svg @click="delete_counter(counter)" aria-hidden="true" focusable="false" data-prefix="fas"
                    data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512">
                    <path fill="currentColor"
                      d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
                    </path>
                  </svg>
                </li>
              </ul>
            </div>
            <p style="text-align:right;margin-bottom:0">
              <a @click="modal_add_counters = true" class="button button-red">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus"
                  class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor"
                    d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
                  </path>
                </svg>
                <span>Schalter hinzufügen ›</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_counters">
      <svg @click="modal_add_counters = false" aria-hidden="true" focusable="false" data-prefix="far"
        data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Schalter hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap">
          <label for="name" class="form-label">Schalter <span class="rq">(* Pflichtfeld)</span></label>
          <select v-model="counter.counter_id" class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}
            </option>
          </select>
        </div>

        <div v-if="users && users.length > 0" class="form-wrap">
          <label for="name" class="form-label">Mitarbeiter, die die Leistung bearbeiten</label>
          <select v-model="counter.user_ids" class="form-input" multiple>
            <option v-for="user in users" v-bind:key="user.id" v-bind:value="user.id">{{ user.first_name }} {{
              user.last_name }}
            </option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="match_type" class="form-label">Termine, die diese Leistung beinhalten verknüpfen?</label>
          <select v-model="counter.match_type" class="form-input">
            <option key="first_letter" value="first_letter">Ja, Anhand des Anfangsbuchstabens</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="match_value" class="form-label">Wert für die Verknüpfung, z. B. Anfangsbuchstabe(n)</label>
          <input v-model="counter.match_value" class="form-input" type="text" name="match_value" id="match_value">
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_counter" class="button button-red button-100">Schalter hinzufügen ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
import CurrencyInput from '@/components/CurrencyInput'

export default {
  name: 'admin_service',
  components: { CurrencyInput },
  data() {
    return {
      modal_add_counters: false,
      loading: true,
      service: {},
      categories: [],
      counters: [],
      service_counters: [],
      languages: [],
      counter: {},
      users: []
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/services/' + this.$route.params.uuid + '/counters', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.service_counters = response.data.counters;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/services/' + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.service = response.data.service;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/languages', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.languages = response.data.languages;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/categories', { params: { all: true }, headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.categories = response.data.categories;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/counters', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.counters = response.data.counters;
        })
      this.loading = false
    },
    async update_service() {
      console.log("JO")
      this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/services/' + this.$route.params.uuid, this.service, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    create_counter() {
      this.$http.post(process.env.VUE_APP_BASE_API + '/admin/services/' + this.$route.params.uuid + '/counters', this.counter, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_add_counters = false
          this.counter = {}
          this.users = []
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    delete_counter(counter) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/services/' + this.$route.params.uuid + '/counters/' + counter.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
          })
          .catch(error => {
            error.response.data.errors.forEach(value => {
              this.$notify({
                title: value,
                type: "error"
              });
            });
          });
      }
    },
  },
  mounted() {
    this.get_data();
  },
  watch: {
    'counter.counter_id': async function () {
      console.log("JO1")
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users', { params: { counter_id: this.counter.counter_id }, headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.users = response.data.users;
        })
    }
  }
}
</script>

<style lang="scss">
</style>
